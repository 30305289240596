<template>
  <div
    class="flex h-full w-full flex-col items-center justify-between gap-2 bg-white p-2 group-hover:bg-slate-100 @lg:flex-row"
  >
    <div class="flex flex-wrap gap-2 @lg:w-[20%]">
      <img
        v-for="logo in logos"
        :key="logo.url"
        :src="logo.url"
        class="h-auto"
        :alt="logo.alt"
      />
    </div>

    <div
      class="flex h-full w-full flex-col justify-center @sm:w-1/2 @md:w-[80%]"
    >
      <div>
        <AuctionListItemTitle />
        <AuctionDescriptions :descriptions="descriptions" />
      </div>

      <AuctionListItemNotice v-if="notice" v-bind="notice" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { DisplayingAuction } from '@autobid/ui/types/components/AuctionsData'
import AuctionDescriptions from '../shared/AuctionDescriptions.vue'
import AuctionListItemNotice from './AuctionListItemNotice.vue'
import AuctionListItemTitle from './AuctionListItemTitle.vue'

const { logos, descriptions, notice } = inject('auction') as DisplayingAuction
</script>
